import React from 'react';
import { AiOutlineStar, AiFillFacebook, AiFillTwitterSquare, AiFillPlusSquare } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const Aboutus = () => {
  return (
    <>
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Insurance Agency | About Us | Darshan Prime Agency</title>
        <link rel="canonical" href="https://darshanprime.com/Aboutus" />
        <meta
          name="description"
          content="Darshan Prime Agency - Premier Insurance Agency for all your solutions. We are one of the top leading insurance agencies in Bangalore. Discover unbeatable insurance solutions today. Protect and Secure your future with us!"
        />
        <meta
          name="keywords"
          content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"
        />
      </Helmet>
      <div className="city">
      <h1 className="text-3xl">
  <span className="font-bold flex justify-center">ABOUT ME</span>
</h1>

      </div>
      <div className="container mx-auto p-4 rounded-lg">
        <h2 className="text-3xl font-bold mb-5 mt-6">About Me and my Team</h2>
        <p className="text-gray-600 mb-5 ">Its an easy task to be an LIC agent but hard to procure business in Bangalore. The role of my team will help you reach the professional goal.</p>
        <h3 className="text-2xl font-semibold mb-4">Why you should be an LIC agent and be our teammates?</h3>
        <p className=' mb-4'>I, myself Umesh B B, have been associated with LIC of India as a Development Officer for the last 10 years.</p>
        <p className=' mb-4'>My job is my passion to achieve success, and success means achieving financial goals as well as professional recognition.</p>
        <p className=' mb-4'>My whole experience belongs to consulting with successful individuals in the industry.</p>
        <p className=' mb-4'>Whether you are looking for a Part-time Job/Second Income LIC Agency Career/Insurance Agent profession in Mumbai.</p>
        <h4 className="text-2xl font-semibold mb-4">Pleasure will be mine if I help you with the following:</h4>
        <ul>
          <li>Get rid of financial crunch at any stage of your life.</li>
          <li>Earning should be your choice, not compulsion.</li>
          <li>Financial burden should be mitigated.</li>
          <li>Fulfillment of your financial dream, like own car, house, child education/marriage, etc.</li>
        </ul>
        <p className=' mb-4'>I am happy to inform you that you will be facilitated in my team through Regular meetings about LIC’s Plans and Market knowledge.</p>
        <p className=' mb-4'>Goal setting for your dream. Your target or benchmark for your work will be created by you only.</p>
        <ul>
          <li>Optimum use of technology to have knowledge and to get leads from the Market.</li>
          <li>You will enjoy the pressure to perform.</li>
        </ul>
        <h1 className="text-3xl font-bold mb-5 mt-6">Why join our team?</h1>
        <p className=' mb-4'>We are working as a team. Being an LIC agent is not enough in the current market scenario; we have to keep ourselves updated with all financial knowledge. We are arranging training sessions on a regular basis with different speakers.</p>
        <p className=' mb-4'>Our training has three parts as below</p>
        <ul>
          <li>Motivation.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
        </ul>
        <p className=' mb-4'>LIC agents are facing very tough competition in the market, especially with Private Insurance Players, Post office, Mutual Funds, etc., even though LIC is the only insurance company with Government Stake. To face such competition, agents must have knowledge of their products as well as USP of LIC products, so training is an important part.</p>
        <ul>
          <li>Motivation.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
          <li>Motivation.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
          <li>LIC Product Knowledge.</li>
          <li>Sales and Marketing to Make Prospects.</li>
        </ul>
        <hr />
        <h1 className="text-1xl font-bold mb-4">Like this:</h1>
        <button className="flex items-center border border-blue-500 hover:border-blue-700  hover:bg-blue-700 font-bold py-2 px-4 rounded">
          <AiOutlineStar /> Like
        </button>
        <h1 className="text-1xl mb-4">Be the first to like this.</h1>
        <div className="flex items-center h-90 w-90">
          <AiFillFacebook className="h-10 w-10 text-blue-500" />
          <AiFillTwitterSquare className="h-10 w-10 text-blue-500" />
          <AiFillPlusSquare className="h-10 w-10 text-blue-500" />
        </div>
      </div>
    </div>
    </>
  );
}

export default Aboutus;
