import React from 'react';
import img1 from "./city.jpg";
import img from "./img3.jpg";
import {AiOutlineStar,AiFillFacebook,AiFillTwitterSquare,AiFillPlusSquare} from "react-icons/ai";
import {Helmet} from "react-helmet";
const Working = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>LIC Agent Work Mechanism | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Working" />
                <meta name="description" content="Discover the inner workings of a LIC Agent's career and how they help to secure your financial future. For more information Contact Us today!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
    <div className='city'>
    <h1 className='flex justify-center'><b>LIC Agent Work Mechanism</b></h1>
    </div>
    <img className='' src={img1} width="100%"/>
    <div class="container mx-auto p-4 rounded-lg">
    
    <h1 class="text-3xl font-bold mb-4">LIC Agent Work Mechanism</h1>
    <p className='mb-4'>If you are thinking to be LIC agent and want to earn huge ,then you have to learn mechanism of this Business.</p>
    <p className='mb-4'>These questions may come to your mind ,</p>
    <p className='mb-4'>How LIC agency business is useful to you ?</p>
    <p className='mb-4'>How LIC agency works ?</p>
    <p className='mb-4'>Are you able to do this LIC Agent Job? Or it is below your level</p>
    <img src={img}/>
    <p className=' mt-4 mb-4'>Lets understand this fact.</p>
    <p className='mb-4'>Mr ‘X’ should buy Insurance but why ? because he needs Saving,Pension,Tax Benefits Etc.</p>
    <p className='mb-4'>Then Mr ‘X’ have lots of options available , he may park his money into Bank,Equity Market,Real estate etc.</p>
    <p className='mb-4'>But there is No Life Risk Cover would be there.</p>
    <p className='mb-4'>So Mr’X’ will go for either LIC Of India or any Pvt Organization.</p>
    <p className='mb-4'>Obviously  Mr ‘X’ will take LIC Insurance product, because it is Government Flavoured Organization.</p>
    <p className='mb-4'>In LIC of India ,only LIC Agent can sell LIC Policy .So its compulsory to Mr ‘X” to contact LIC agent for LIC policy.</p>
    <hr></hr>
       <h1 class="text-1xl font-bold mb-4">Like this:</h1>
       <button class="flex items-center border border-blue-500 hover:border-blue-700  hover:bg-blue-700 font-bold py-2 px-4 rounded">
   <AiOutlineStar/> Like
    </button>
    <h1 class="text-1xl  mb-4">Be the first to like this.</h1>
    <div class="flex items-center h-90 w-90">
    <AiFillFacebook  class="h-10 w-10 text-blue-500" />
    <AiFillTwitterSquare class="h-10 w-10 text-blue-500"/>
    <AiFillPlusSquare class="h-10 w-10 text-blue-500"/>
    </div>
   

  
</div>
    </div>
  
  );
}

export default Working;
