import React from 'react';
import {AiOutlineStar,AiFillFacebook,AiFillTwitterSquare,AiFillPlusSquare} from "react-icons/ai";
import img1 from "./city.jpg";
import vid1 from "./video.mp4";

import {Helmet} from "react-helmet";
const Milestone = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>LIC Milestone Agents | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Milestone" />
                <meta name="description" content="Become a part of the success story with LIC Milestone Agents. Join our elite team today to achieve your financial milestones, secure your future, and take your career to the next level with Darshan Prime Agency." />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
    <div className='city'>
      <h1 className='flex justify-center'><b>LIC Milestone Agents</b></h1>
      </div>
      <img className="mt-" src={img1} width="100%"/>
      <div class="container mx-auto p-4 rounded-lg">
      
      <h1 class="text-4xl font-bold mt-4">Bharath Parekh</h1>
      <p className='mt-4'>Parekh, who missed out on watching matinee shows and playing cricket during his youth, is now a millionaire who can afford most luxuries of life. As an agent, his annual income is over Rs 4 crore, around five times more than the Rs 87 lakhs that LIC chairman DK Mehrotra took home last year. The sweat and toil behind selling 1,000 odd policies a year, helped Parekh marry his five sisters off in accordance with the demands of Indian tradition.</p><br></br>
      <p>He sells around 1,000 policies a year, the worth of a policy being Rs 2 lakh on an average, and manages to generate a premium of Rs 200 crore for the corporation — the highest generated by any agent in India.</p>
      <h1 class="text-3xl font-bold mt-4">Bharath Parekh  Journey as himself</h1>
      <ul>
        <li>Invest as much as you can in your staff.</li>
        <li>I take 4 long holidays in a year, 15 days each.</li>
        <li>We have to call….no alternative.</li>
        <li>My secretary salary: 50,000 per month.</li>
        <li>LIC agent since 1985.</li>
        <li>Became LIC agent at age 18.</li>
        <li>I have 40,000 Policyholder till today.</li>
        <li>700 new policies per year I sold.</li>
        <li>Average salesperson only works for 90 minutes in a   day (when he is in front of customer or calling him).</li>
      </ul>
      <h1 class="text-2xl font-bold mt-4">Bharath Parekh says</h1>
        <p className='mt-4'>Most productive time of the day is 8 to 10 in the morning. You can sell anything during this time. Successful People work for 12 hours per day.</p>
        <ul>
        <li> TOT agent spends 78% of their time in-front of prospect.</li>
        <li> MDRT agents spend 68% of their time in-front of their prospects.</li>
        <li> COT agents spend 72% of their time in-front of their prospects.</li>
        </ul>
        <p className='mt-4'>Health comes from good habits, Wealth comes from good work Habits. We have to do unpleasant things.</p>
        <p className='mt-4'>I only sleep on Sunday Afternoon and then study.</p>
        <p className='mt-4'>Customer knows more than you. Tell him everything.</p>
        
        <video controls autoPlay muted loop src={vid1} height="5%" width="100%"/>
        <h1 class="text-2xl font-bold mt-4">LIC’s Agent Vakalapudi</h1>
        <p className='mt-4'>Every time Venkateswara Rao Vakalapudi thought of an insurance agent, the image that came to his mind was of a pesky person thrusting insurance policies under people’s noses. This was one profession he was certain that he never wanted to pursue. Yet,</p>
        <h1 class="text-2xl font-bold mt-4">ACHIEVEMENT</h1>
        <p className='mt-4'>Today he is Life Insurance Corporation of India’s (LIC) record-breaking star insurance agent.Last year, he earned LIC Rs 51 crore worth of fresh business, an industry record. To put this in perspective, 60 per cent of insurance agents in India do business worth Rs 2 lakh to Rs 3 lakh a year. Vakalapudi, 42, who grew up in a village in West Godavari in Andhra Pradesh, today drives a Range Rover Sport, has a seafacing bungalow at Vizag, has high networth individuals (HNI), nonresident Indians (NRIs) and corporate houses as his clients and travels around the world for business and to give motivational talks.</p><br></br>
        <h1 class="text-2xl font-bold mt-4">Journey</h1>
        <p className='mt-4'> In 1997, his father died. Vakalapudi went into depression, moved back to the village and starting helping his brother with the farm. But he soon shifted to Vizag and started a poultry business. He would collect feed from companies like Hindustan Unilever and Godrej and sell it to farmers for a credit. The going was good. He repaid his debts. But three years later, the farming community found itself in stress. Business plunged. Around his time, some of Vakalapudi’s friends from the engineering college in Hyderabad moved to Vizag. Besides helping them relocate, Vakalapudi introduced them to his insurance agent from whom he had bought a policy for himself and his mother. But the agent was erratic and his friends weren’t satisfied with him.</p>
        <h1 class="text-2xl font-bold mt-4">Journey</h1>
        <p className='mt-4'>Vakalapudi’s approach is different from other agents,” says Raghu. “He first sells himself to the prospective customer before he sells the product.” He listens well, tries to closely follow the customer’s needs, inspires confidence in himself and then offers the policy, says the development officer. It is this unique attitude and approach that has made him successful, Raghu adds.</p>
        <hr></hr>
        <h1 class="text-1xl font-bold mt-2 mb-4">Like this:</h1>
        <button class="flex items-center border border-blue-500  hover:border-blue-700  hover:bg-blue-700 font-bold py-2 px-4 rounded">
    <AiOutlineStar/> Like
     </button>
     <h1 class="text-1xl  ">Be the first to like this.</h1>
     <div class="flex items-center h-90 w-90">
     <AiFillFacebook  class="h-10 w-10 text-blue-500" />
     <AiFillTwitterSquare class="h-10 w-10 text-blue-500"/>
     <AiFillPlusSquare class="h-10 w-10 text-blue-500"/>
      </div>
    </div>
    </div>
  );
}

export default Milestone;
