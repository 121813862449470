import React, { useState, useRef } from 'react'
import {AiOutlineStar,AiFillFacebook,AiFillTwitterSquare,AiFillPlusSquare} from "react-icons/ai";
import emailjs from '@emailjs/browser';
import {Helmet} from "react-helmet";

const Apply_Online = () => {
 
    const [formData, setFormData] = useState({
      name: "",
      location: "",
      mobile: "",
      email: "",
      message: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  

    const form = useRef();

    const sendEmail = (event) => {
      event.preventDefault();
      // Location: {{user_location}}
    
  
      emailjs.sendForm('service_det5ee9', 'template_qph056k', form.current, 'NTDd3HkIHm4tnyNuh')
        .then((result) => {
            console.log(result.text);
            console.log("message sent")
        }, (error) => {
            console.log(error.text);
        });
    };
  
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Apply online | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Apply_Online" />
                <meta name="description" content="Do you want to become a LIC Agent in Bangalore? Apply now and start your career as an LIC Agent with Darshan Prime Agency. contact us today!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>

            </Helmet>
    <div className='city'>
    <h1 className='flex justify-center'><b>Apply Online for LIC Agency in Bangalore</b></h1>
    </div>
    <div class="container mx-auto p-4 rounded-lg">
    <h1 class="text-1sm font-semibold mb-6">Apply Online for LIC Agency</h1>
    <form className="flex flex-col p-4 space-y-4" onSubmit={sendEmail}>
    <div className="flex flex-col">
      <label className="text-sm font-medium" htmlFor="name">
        Name<span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        id="name"
        name="user_name"
        value={formData.name}
        onChange={handleChange}
        required
        className="border rounded-md px-3 py-2 outline-none focus:ring focus:border-blue-500"
      />
    </div>
    <div className="flex flex-col">
      <label className="text-sm font-medium" htmlFor="location">
        Location<span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        id="location"
        name="user_location"
        value={formData.location}
        onChange={handleChange}

        required
        className="border rounded-md px-3 py-2 outline-none focus:ring focus:border-blue-500"
      />
    </div>
    <div className="flex flex-col">
      <label className="text-sm font-medium" htmlFor="mobile">
        Mobile<span className="text-red-500">*</span>
      </label>
      <input
        type="tel"
        id="mobile"
        name="user_number"
        value={formData.mobile}
        onChange={handleChange}
        required
        className="border rounded-md px-3 py-2 outline-none focus:ring focus:border-blue-500"
      />
    </div>
    <div className="flex flex-col">
      <label className="text-sm font-medium" htmlFor="email">
        Email<span className="text-red-500">*</span>
      </label>
      <input
        type="email"
        id="email"
        name="user_email"
        value={formData.email}
        onChange={handleChange}
        required
        className="border rounded-md px-3 py-2 outline-none focus:ring focus:border-blue-500"
      />
    </div>
    <div className="flex flex-col">
      <label className="text-sm font-medium" htmlFor="message">
        Message
      </label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        required
        className="border rounded-md px-3 py-2 outline-none focus:ring focus:border-blue-500"
      />
    </div>
    <button
      type="submit"
      value="Send"
      className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md focus:outline-none"
    >
      Submit
    </button>
  </form>
  <h2> Documents Required to be LIC Agent</h2>
  <ol class="list-decimal list-inside text-justify">
  <li>PAN</li>
  <li>ADHAR</li>
  <li>EDUCATION PROOF</li>
  <li>BIRTH PROOF</li>
  <li>PHOTOS</li>
  <li>SIGN</li>
  </ol>
  <h6>All documents should be scanned and sent to us the official email ID that you will get after filling contact form.</h6>
  <hr></hr>
  <h1 class="text-1xl font-bold mb-4">Like this:</h1>
  <button class="flex items-center border border-blue-500 hover:border-blue-700  hover:bg-blue-700 font-bold py-2 px-4 rounded">
<AiOutlineStar/> Like
</button>
<h1 class="text-1xl  mb-4">Be the first to like this.</h1>
<div class="flex items-center h-90 w-90">
<AiFillFacebook  class="h-10 w-10 text-blue-500" />
<AiFillTwitterSquare class="h-10 w-10 text-blue-500"/>
<AiFillPlusSquare class="h-10 w-10 text-blue-500"/>
</div>
    </div>
    
    </div>
  )
}

export default Apply_Online
