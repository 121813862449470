import React from 'react'
import Img from "../Images/Benefits.webp"
import {
  Carousel,
  initTE,
} from "tw-elements";
import AutoPlayCarousel from "./AutoPlayCarousel";
import {Helmet} from "react-helmet";

const Benefits = () => {
  initTE({ Carousel });
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Benefits | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Benefits" />
                <meta name="description" content="Explore the benefits and advantages of Darshan Prime Insurance Agency, We are offering a wide range of insurance plans and policies with multiple coverage options. Protect and secure yourself and your family with us." />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>

            </Helmet>
    <div>
      <img src={Img} alt='' width="100%" height="500px"/>
    </div>
    <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-9 col-md-6">
              <h2>Key Benefits</h2>
              <li>Flexible working hours</li>
              <li>Assured future income after 5years of service</li>
              <li>Assured HRC(Hereditary Renewal Commission) income to spouse & children after 10years of service</li>
              <li>Housing loan at subsidized interest rate</li> 
              <li>Interest free loan for  2-4 wheeler, festival, marriage, computer /laptop depending on class of agent </li>
              <li>Freelancing career opportunity</li>
              <li>Win an entry chance in insurance field with/without formal education</li>
              <li>Unlimited income compensating your work in the form of commission payable monthly/bimonthly</li>
              <li>Gratuity up to 2lakh</li>
              <li>Medicaid Rs 50000-200000 depending on class of agent</li>
              <li>Group Insurance</li>
              <li>Foreign trips sponsored by LIC</li>
              <li>International appreciation and acclaim for your performance</li>
              <li>Build a career in training with your vast experience</li>
              </div>
              <div className="col-lg-3 col-md-6 classs" >
                <div className="section-header">
                <h3>How to get Started?</h3>
                </div>
                <div className="about-text" data-aos="fade-up" data-aos-duration="1000">
                
                  <p>
                  Kindly fill in the (Join our team) form below and we shall get in touch with you.
                  </p>
                  <p></p>
                  <button class="button"><span><a href="tel:xxxxxxxxxx" className='text-black'>HELPLINE</a> </span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div>
        <h4 className='flex justify-center'><b>PHOTO GALLERY</b></h4>
        <div>
       <AutoPlayCarousel/>
        </div>
      </div>
    </div>
  )
}

export default Benefits
