import React from 'react';
import img from "./img4.jpg";
import img2 from "./procedure.png";
import {AiOutlineStar,AiFillFacebook,AiFillTwitterSquare,AiFillPlusSquare} from "react-icons/ai";
import {Helmet} from "react-helmet";
const How = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>How to Become LIC as an Agent in Bengaluru | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/How" />
                <meta name="description" content="Are You looking for an opportunity to become an LIC Agent In Bangalore? Darshan Prime Agency is looking for candidates who build customer relationships and grow business. Contact and Visit us Today!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
    <div className='city'>
      <h1 className='flex justify-center'><b>How to Become  LIC as an Agent in Bengaluru</b></h1>
      </div>
      <img className="how mt-2" src={img}/>
      <div class="container mx-auto p-4 rounded-lg">
     
      <p class="text-gray-600 mt-4">As we know LIC agents are the only professionals who works in the LIC of India at grass root level. They are actually intermediaries who generate ties between LIC  and their clients (Proposer) . A Person who satisfy the eligibility criteria of LIC agent ,set up by the LIC ,can become LIC Agent.</p><br></br>
        <p class="text-gray-600">Life Insurance Corporation of India is the best  in the insurance company for several decade  with thousands of policies holders and safeguarding every aspect of an individual’s life cover. This Insurer  is developed by  LIC agents, who  is caring to secure the lives of different customer base. The question arises as how to become LIC agent in Bengaluru ? or How to become LIC advisor in Mumbai?or What is New Procedure to be LIC agent?
        </p><br></br>
        <h1 class="text-2xl mb-4">Process of LIC agent Recruitment is as below:
        </h1>
        <ul>
        <li>Fill a sponsorship form in LIC branch</li>
        <li>Get Online/offline Training about Insurance Industry</li>
        <li>Face online Exam conducted by NSEIT for one who want to join LIC as Agent.</li>
        <li>Documentation at branch of all education proof ,age proof,bank details and address proof along with two photographs.</li>
      </ul>
      <img className="img2" src={img2} alt="img2"/>
      <h1 class="text-1xl font-bold mb-4">Required Cost/Investment/Fee for  to be LIC Agent</h1>
      <h1 class="text-1xl  mb-4">There are three basic investment is needed to be LIC agent in Mumbai.
      </h1>
      <ul>
      <li>Labour</li>
      <li>Time to devote and</li>
      <li>A burning desire to do great in LIFE
      </li>
      </ul>
      <h1 class="text-1xl  mb-4">As per ass fee concern, Every one have to pay
      </h1>
      <ul>
      <li>Rs 425 as Exam fee for NSEIT online Exam</li>
      <li>Receipt Rs 150 along with Sponsorship form</li>
      </ul>
      <p class="text-gray-600 mt-4">Now These professionals, in their regular affairs to deal with clients to  explain them the several  types of  LIC’s insurance policies, like Endowment Plans, Term insurance , Mediclaim Insurance and so on .Its also their duty also convince the individual and make them convenient to take a LIC policy.</p><br></br>
      <p class="text-gray-600 mt-4">Hence,  All Product training programme is conducted by LIC as well as me as development officer.Also, all  supporting stuff will be provided by us.</p><br></br>
      <p class="text-gray-600 mt-4">LIC agents are in a part/full /any time profession in Mumbai without time constrain. They are getting  great  commissions as professional fee from LIC with their time flexibility.Even if working as weekend jobs or Saturday /Sunday job any one can earn huge. Easily one can consider it home based job which can make prosperous.</p><br></br>
      <a href='https://www.licagentportal.in/career-in-lic-of-india-2023-24/'>https://www.licagentportal.in/career-in-lic-of-india-2023-24/</a>
      <hr></hr>
      <h1 class="text-1xl font-bold mb-4">Like this:</h1>
       <button class="flex items-center border border-blue-500 hover:border-blue-700  hover:bg-blue-700 font-bold py-2 px-4 rounded">
   <AiOutlineStar/> Like
    </button>
    <h1 class="text-1xl  mb-4">Be the first to like this.</h1>
    <div class="flex items-center h-90 w-90">
    <AiFillFacebook  class="h-10 w-10 text-blue-500" />
    <AiFillTwitterSquare class="h-10 w-10 text-blue-500"/>
    <AiFillPlusSquare class="h-10 w-10 text-blue-500"/>
      </div>
      </div>
    </div>
  );
}

export default How;
