import React from 'react'
import Carousels from '../Pages/Carousels'
import "../style/Home.css"
import Img from "../Images/5-01.jpg";
import Img2 from "../Images/about2.webp";
import Img3 from "../Images/7.jpg";
import Img4 from "../Images/6.jpg";
import {Link } from "react-router-dom"
import {Helmet} from "react-helmet";


const Home = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Insurance Agency In Bangalore | Home | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Home" />
                <meta name="description" content="Darshan Prime Agency is a leading Insurance Agency in Bangalore, India. We offer various types of Insurance Plans & Policies such as Life, Health, Motor, Travel, Home, Retirement, Term, and more to protect you and your family. Click here to buy the best insurance plans and secure your future today with us!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
      
      <Carousels/>
<div className='Contaier'>
  <h2 className=' flex items-center justify-center pt-20'>About us</h2>
</div>
<div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="about-img rounded-sm imag" data-aos="fade-right" data-aos-duration="1000">
                  <img src={Img} alt="Images" className='rounded-3xl'  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 classs" >
                <div className="section-header">
                
                </div>
                <div className="about-text" data-aos="fade-up" data-aos-duration="1000">
                <h3 className='flex justify-center'>Golden opportunity</h3>
                  <p className='flex justify-center'> Start your career as LIC advisor</p>
                  <p></p>
                  <button class="button flex justify-content-around flex-wrap  "><span>  <Link  to="tel:9108166064" className='no-underline text-black '> 
                     Call us
                      </Link></span></button>
                      <button class="button flex justify-content-around flex-wrap    "><span>  <Link  to="tel:8095882225" className='no-underline text-black '> 
                     Call us
                      </Link></span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about">
        <div className="container">
          <div className="right">
            <div className="row align-items ">
              <div className="col-lg-6 col-md-6">
                <div className="about-img imag"   >
                  <img src={Img4} alt="Images" className='rounded-3xl  ' />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="section-header " >
                  <h2>ABOUT US</h2>
                </div>
                <div className="about-text" >
                  
                  <ul>
                  <li>LIC is the No. 1 brand in service sector and 240 million + lives are part of LIC of India.</li>
                  
                  <li>Best infrastructure – 2048 LIC branches (fully computerized) 1275 satellite offices, more than 1.16 lac employees and 11.72 lac agents.</li>
                  </ul>
                  <p></p>
                  {/* <a className="btn" href="">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <div className="about-img imag1" data-aos="fade-right" data-aos-duration="3000">
                <img src={Img2} alt="Images" className='rounded-3xl' />
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="section-header" data-aos="fade-down" data-aos-duration="3000">
                <h2>Our Strengths</h2>
              </div>
              <div className="about-text" data-aos="fade-up" data-aos-duration="3000">
              <ul>
               <li>Best practices in the industry</li>
               <li>Excellent claim settlement ratio</li>
               <li> Sovereign guarantee to customers</li>
</ul>
             
               
                {/* <a className="btn" href="">
                  Learn More
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
          <div className="right">
            <div className="row align-items ">
              <div className="col-lg-6 col-md-6">
                <div className="about-img imag"   >
                  <img src={Img3} alt="Images"  className='rounded-xl'/>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="section-header" >
                  <h2>Social Reach</h2>
                </div>
                <div className="about-text" >
                <ul>
                  <li>LIC policy for all segment</li>
              
                  <li>Transparency operations.</li>
            
                  <li>LIC is the biggest life insurer in the world.</li>
                  </ul>
                  <p></p>
                  {/* <a className="btn" href="">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div>
        <h1 className='items-center flex justify-center '>VIDEO</h1>
<h5 className='items-center  flex justify-center  '>Check out this great video</h5>
      </div>
      <div className='Voides'>
      <iframe width="100%" height="700px" src="https://www.youtube.com/embed/9g2Em1sONHY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share " allowfullscreen></iframe>
      </div>
     
    </div>
  )
}

export default Home
