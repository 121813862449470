import React from 'react'

import {Helmet} from "react-helmet";
const Achievers = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Achievers | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Achievers" />
                <meta name="description" content="Darshan Prime Agency, your trusted insurance partner. Check our Achievers, Who achieved & reached their milestones & goals through dedication & hard work. Join our elite team today and grow your future with us!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
      <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="about-img" data-aos="fade-right" data-aos-duration="1000">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/aXH_R6iaOCs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 classs" >
                <div className="section-header">
                
                </div>
                <div className="about-text" data-aos="fade-up" data-aos-duration="1000">
                <h3 className="flex justify-center "> Shri V. K. Sharma, Chairman LIC</h3>
                  <p className="flex justify-center ">2017 </p>
                  <p></p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about"> 
        <div className="container">
          <div className="right">
            <div className="row align-items ">
              <div className="col-lg-5 col-md-6">
                <div className="about-img"   >
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/vDeJTYZGmQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header" >
                  
                </div>
                
                <div className="about-text" >
                <br></br>
                <h2 className="flex justify-center ">LIC Sapno Ki Udaan</h2><br></br>
                <h1 className="flex justify-center ">Rajeev Arya & Kapil Juneja</h1>
                 
                  {/* <a className="btn" href="">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="about-img" data-aos="fade-right" data-aos-duration="1000">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/CsVlTSTVhAw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 classs" >
                <div className="section-header">
                <h3 className="flex justify-center "> Successful LIC Agents</h3>
                </div>
                <div className="about-text" data-aos="fade-up" data-aos-duration="1000">
                
                  <h5 className="flex justify-center ">Sanjay Kumar Sanghvi & Vijay Chandak</h5>
              
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about">
        <div className="container">
          <div className="right">
            <div className="row align-items ">
              <div className="col-lg-5 col-md-6">
                <div className="about-img"   >
               
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/X3Tq5uUfFKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header" >
                  
                </div>
                <div className="about-text" >
                <h2  className="flex justify-center ">Star LIC Agents</h2>
                 <p  className="flex justify-center ">Sangeetha Maheshwari & Hitesh shah</p>
                
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="about-img" data-aos="fade-right" data-aos-duration="1000">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/X4Cdzs_m0zQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 classs" >
                <div className="section-header">
                <h3 className="flex justify-center "> LIC-Circle of life</h3>
                </div>
                <div className="about-text" data-aos="fade-up" data-aos-duration="1000">
                
                 
              
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Achievers
