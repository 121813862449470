import React from 'react';
import Img from "../Images/Documents.jpg";
import Img1 from "../Images/Pre-Requisites.jpg";
import Img2 from "../Images/Post Exam.jpg";
import Img3 from "../Images/Proseger.jpg";
import {Helmet} from "react-helmet";
const Procedure = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Procedure | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Procedure" />
                <meta name="description" content="Are you looking for an opportunity to become an LIC Agent? look no further than Darshan Prime Agency, We are here to help you to achieve your goals and Secure your future. Check out the procedure and apply now!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
    <img src={Img3} alt='' width="100%" height="5%"/>
    <h2 className=' flex justify-center  pl-5 pt-20'>PROCEDURE FOR BECOMING AGENT</h2>
    <br></br>
    <hr></hr>
      <div class="containers">
    <div class="card " margen-right="20px">
      <div class="card-header">
        <img src={Img} alt="" />
      </div>
      <div class="card-body">
       
        <h4>Documents Required</h4>
       <ol>
        <li>1. Passport size photo</li>
        <li>2. Aadhar Card</li>
        <li>3. Pan Card</li>
        <li>4. Bank Account</li>
        <li>5. Driving License</li>
        <li>6. SSLC Marksheet</li>
        <li>7. Bangalore Address Proof</li>
       </ol>
       
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <img src={Img1} alt="" />
      </div>
      <div class="card-body">
       
        <h4>Pre-Requisites</h4>
       <ul>
        <li>150 Registration Fees.</li>
       <li>150 Online Training Fees. </li>
       <li>500 IRDA Examination Fee.</li>
       <li>200 for Study material</li>
       <li>Total:  1000    </li>
       <li>Min pass mark (18/50)</li>
       </ul>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <img src={Img2} alt="" />
      </div>
      <div class="card-body">
      
        <h4>Selection Process</h4>
        <p className='flex text-justify'>
        Fill up the application form at end of seminar or apply online on www.licguru.in
       
        Appear for Personal Interview.
        Appear for Aptitude Test.
       After selection you have to undergo Induction Training once in a week spread over one month and complete assignments given.
        Passing of IRDA Examination.
        Issue of Appointment Letter By LIC of India.
        You will be attached to LIC of India Bhandup (w) Branch Mumbai.</p>
        {/* <div class="user">
          <img src="https://3.bp.blogspot.com/--sCpJJGYWEA/W2P4C51CYSI/AAAAAAAAQcI/LR4U_--Wf1E3wz7RLZtmwBPObm_ky9tQQCLcBGAs/s1600/beautiful-indian-women-photos-1.jpg" alt="" />
          <div class="user-info">
            <h5>Carrie Brewer</h5>
            <small>23 Dec 2020</small>
          </div>
        </div> */}
      </div>
    </div>
  </div>
    </div>
  )
}

export default Procedure
