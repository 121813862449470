import React from 'react';
import {Helmet} from "react-helmet";
import {AiOutlineStar,AiFillFacebook,AiFillTwitterSquare,AiFillPlusSquare} from "react-icons/ai";
const Licagent = () => {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>LIC Agent in Bangalore | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Licagent" />
                <meta name="description" content="Find your trusted LIC Agent in Bangalore with Darshan Prime Agency for expert insurance guidance. Our dedicated agents are here to secure your future. Connect with us today for personalized insurance solutions." />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
    <div className='city'>
    <h1 className='flex justify-center'><b>LIC Agent in Bangalore</b></h1>
    </div>
    <div class="container mx-auto p-4 rounded-lg">
    <hr></hr>
    <h1 class="text-1xl font-bold mb-4">Like this:</h1>
    <button class="flex items-center border border-blue-500 hover:border-blue-700  hover:bg-blue-700 font-bold py-2 px-4 rounded">
<AiOutlineStar/> Like
 </button>
 <h1 class="text-1xl  mb-4">Be the first to like this.</h1>
 <div class="flex items-center h-90 w-90">
 <AiFillFacebook  class="h-10 w-10 text-blue-500" />
 <AiFillTwitterSquare class="h-10 w-10 text-blue-500"/>
 <AiFillPlusSquare class="h-10 w-10 text-blue-500"/>
 </div>



</div>
    </div>
  );
}

export default Licagent;
