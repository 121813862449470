import React, { useRef,useState } from 'react';
import {Link} from "react-router-dom";
import { IoMdCall,IoMdMail } from "react-icons/io";
import emailjs from '@emailjs/browser';
import {Helmet} from "react-helmet";

const Contactus = () => {

  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();
    // Location: {{user_location}}
  

    emailjs.sendForm('service_det5ee9', 'template_qph056k', form.current, 'NTDd3HkIHm4tnyNuh')
      .then((result) => {
          console.log(result.text);
          console.log("message sent")
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Darshan Prime Agency</title>
                <link rel="canonical" href="https://darshanprime.com/Contactus" />
                <meta name="description" content="Contact Darshan Prime Insurance Agency today for personalized insurance solutions and coverage options. Our dedicated team is here to assist you in protecting what matters most. Reach out now to start the conversation about securing your future with us!" />
                <meta name="keywords" content="Insurance Agency in Bangalore, Insurance Agency, Insurance Company in Bangalore, life insurance, term insurance, life insurance plans, life insurance company, best life insurance policy, Retirement Plan, Life Insurance Agency, Health Insurance, health insurance agency, Motor Insurance, Two Wheeler Insurance, Private Car Insurance, General Insurance, Overseas Insurance, Rural Insurance, vehicle insurance, car insurance companies, classic car insurance, low car insurance, new car insurance, local car insurance companies, car insurance providers, car and insurance, local car insurance agents, medical insurance, medical insurance plans, medical insurance companies, medical insurance coverage, medical insurance policy, medical insurance company, medical insurance brokers, need medical insurance, find medical insurance, medical insurance options, medical insurance providers, inexpensive medical insurance, supplemental medical insurance, medical insurance, private health insurance, private medical insurance, private insurance, Health Insurance Plans, Medical Insurance, Mediclaim Policy, Health Insurance Policy, Best Health Insurance, Online Health Insurance, Medical Insurance Plans, City agent, Insurance Agent, Best Health Insurance in India, Best Medical Insurance, Cashless Health Insurance, Compare Insurance, Buy Insurance, Online Insurance, insurance company India, health insurance, car insurance, home insurance, Travel insurance, India, Companies, Best Insurance Policies, Insurance Broker, Insurance Broking Company, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy, LIC Agents In Bangalore, LIC Agents, LIC"/>
    </Helmet>
      <h1 className='mb-5 mt-10 text-2xl font-bold  flex items-center justify-center text-center '>Contact Us</h1>
      <div className='flex flex-col justify-center lg:flex-row  '>
        <section className=" min-h-screen flex pl-10 pr-5 pt-10 justify-center lg:w-1/2">
          <div className="container mx-auto max-w-3xl  p-4 ">

            <h2 className="text-2xl font-semibold mb-6 mt-0 lg:text-left text-center ">Drop us a line!</h2>
            {/* <form  ref={form} onSubmit={sendEmail}>
              
                <input placeholder='Name' type="text" name="user_name" className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100" required />
                      
                <input placeholder="Email" type="email" name="user_email" className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100" required />
             
              
                <input placeholder='Number' type="phone_number"  name="user_number" className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100" required />
            
                <textarea placeholder="Message" name="message" rows="4" className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100" required></textarea>
              
            
                <button type="submit" value="Send" className="bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600 transition-colors duration-300">SEND</button>
            
            </form> */}
            


   <form id="contactForm" ref={form} onSubmit={sendEmail}>
      <label for="name" >Name:</label>
      <input type="text" name="user_name" id="name" required className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100" />
      <label for="email">Email:</label>
      <input type="email" name="user_email" id="email" required className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100" / >
      <label for="number">numbeer</label>
      <input type="text" name="user_number" pattern="[6789][0-9]{9}" title="Please enter valid phone number" className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100"/>
      <label for="message">Message:</label>
      <textarea id="message" name="message"  required className="w-full px-3 py-2 rounded focus:outline-none focus:border-blue-500 border border-transparent bg-gray-100"/>
      <button type="submit" value="Send" id="submitBtn" className="bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600 transition-colors duration-300">Submit</button>
    </form>



            <p className="mt-6 text-sm text-center sm:text-left">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
          </div>
        </section>
        <section className=" flex flex-col ml-0 mt-12 items-center  lg:ml-10 py-10 ">
          <div className=" ">
            <h1 className="text-2xl font-semibold mb-6 lg:text-left text-center">Better yet, see us in person!</h1>
            <p className="mt-6 text-center sm:text-left">We love our customers, so feel free to visit during normal business hours.</p>
            <div id="wa-chat-btn-root" className=" whatsapp no-underline wa-chat-btn-fixed wa-splmn-chat-btn-offset border border-gray-500 mt-6 bg-gray-800 items-center flex justify-center  " style={{ display: "table" }}>
              <a href="https://wa.me/8016418339" target="_blank" className="flex items-center p-2 ">
                <img className="wa-chat-btn-icon-cta-big w-12   " alt="Whatsapp Chat Button" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_6.4.png?816" />
                <span className="ml-2 text-white no-underline">Message us on Whatsapp</span>
              </a>
            </div>
            <h1 className="text-2xl font-semibold mb-6 mt-6 lg:text-left text-center">Darshan Prime</h1>
            <p className='colors  -ml-5'>
            <Link to="mailto:Hr@darshanprime.com" className='text-white no-underline '>
            <IoMdMail/><p className='text-black ml-5 -mt-5'>Email :  Hr@darshanprime.com</p></Link>
            </p>
            <p className="mt-3">Anugraha Layout, Ramanashree Enclave, Bilekahalli, Bangalore 560076</p>
            <h1 className="text-2xl font-semibold mb-6 mt-0 lg:text-left text-center">Hours</h1>
            <div className='date mt-6 mb-20'>
              <select name="SortBy" id="SortBy" data-default-sortby="manual">
                <option value="manual" selected="selected">Open today
                  10:00 am – 06:00 pm</option>
                <option value="best-selling">Monday 10:00 am – 06:00 pm</option>
                <option value="title-ascending">Tuesday 10:00 am – 06:00 pm</option>
                <option value="title-descending">Wednesday 10:00 am – 06:00 pm</option>
                <option value="price-ascending">Thrusday 10:00 am – 06:00 pm</option>
                <option value="price-descending">Friday 10:00 am – 06:00 pm</option>
                <option value="created-ascending">Sunday 10:00 am – 06:00 pm</option>
                <option value="created-descending">Sunday Closed</option>
              </select>
            </div>
          </div>
        </section>

      </div>
     
    </div>
  );
}

export default Contactus;
